import { device } from "constants/deviceSizes";
import { useTypedSelector } from "hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import styled, { CSSProperties } from "styled-components";

interface NavbarItemProps {
  iconPath?: string;
  activeIconPath?: string;
  isFrench?: boolean;
  isActive?: boolean;
  titleKey?: string;
  onClick?: () => void;
  style?: CSSProperties;
  imageStyle?: CSSProperties;
}

export const NavbarItem = (props: NavbarItemProps) => {
  const {
    iconPath,
    activeIconPath,
    isFrench,
    titleKey,
    onClick,
    isActive,
    style,
    imageStyle,
  } = props;

  const { t } = useTranslation();

  const icon = iconPath ? require(`../../../${iconPath}`) : undefined;
  const activeIcon = activeIconPath
    ? require(`../../../${activeIconPath}`)
    : undefined;

  return (
    <CardWrapper onClick={onClick} style={style}>
      <Card isFrench={isFrench}>
        <img
          src={isActive && activeIcon ? activeIcon : icon}
          style={imageStyle}
        />
        <span>{t(`common.footer.${titleKey}`)}</span>
      </Card>
    </CardWrapper>
  );
};

const Card = styled.div<{ isFrench?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  img {
    max-width: 60px;
    position: center;
  }

  span {
    font-size: 10px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.text.white};
    margin-bottom: 4px;

    @media (${device.mobileXL}) {
      font-size: ${({ isFrench }) => (isFrench ? "8px" : "10px")};
    }
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
`;
